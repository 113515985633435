@import '../../scss/colors';
@import '../../scss/icons';

.severe {
  .coloredHeading { color:$green }

  .show .ia-container {
    .iaGrid .elem.severe { background-image: $foot-green; }
    .legend h6.severe { display: block; }
  }
  

}