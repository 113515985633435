@import '../../scss/colors';
@import '../../scss/icons';

.comfortcare {
  .content {
    article h2 { margin-top: 125px; }

    .body { margin-right: 230px; }
  }

  .coloredHeading { color: $nonsurvival-color; }

  section {
    margin-right: 215px;

    p { margin-top: 10px;}
  }

  .spacer { height: 10px; }

  .video-and-text { margin-top: 0;}

  .show .ia-container {
    .legend h6.nonSurvival { display: block;}
    .iaGrid .elem.nonSurvival{ background-image: $ribbon-color; }
  }

}
