@import '../../scss/colors';
@import '../../scss/icons';

.moderate {
  .coloredHeading { color: $dark-blue}

  .show .ia-container {
    .iaGrid .elem.moderate { background-image: $foot-dark-blue; }
    .legend h6.moderate { display: block;}
  }
}