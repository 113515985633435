.resuscitationintro, .comfortcareintro, .instructions {
  padding: 0 95px 60px 100px;

  .content article {
    margin-top: 30.2vh;

    h2 {
      font-size: 2.4em;
      margin-bottom: 1.5vh;
    }

    .markdown p {
      font-family: 'pt-sans';
      font-size: 1.2em;
      font-weight: bold;
      letter-spacing: 0.045em;
      line-height: 1.3;

      .resuscitation, .comfort-care {
        white-space: nowrap;
        padding-left: 28px;
      }
    }

    ol {
      margin-left: 2.2vw;

      li { margin-bottom: 1.2em;}
    }
  }

}
