@import '../../scss/colors';
@import '../../scss/icons';

.survey {
  h2 { margin-bottom: 15px; }

  article {
    margin: 125px auto;
    width: 520px;

    p {
      font-size: 1em;
      margin-bottom: 18px;
    }

    form {
      margin-top: 60px;

      .flex-container {
        display: flex;
        justify-content: space-between;
        margin-top: 30px;

        .divider {
          font-weight: bold;
          flex: 1 0 auto;
          text-align: center;
        }

        .question {
          flex: 0 1 auto;
          width: 230px;

          .question-text {
            margin: 30px 0 5px 0;
            letter-spacing: 0.01em;
            font-size: 1em;
          }

          select {
            font-size: 0.75em;
            background-color: white;
          }

          input {
            font-size: 0.75em;
            width: 227px;
            -webkit-min-logical-width: 219px;
            display: block;
            font-family: initial;
          }
        }
      }
    }
  }

  .error {
    $white-bubble-padding: 10px;

    font-size: 0.825em;
    font-weight: bold;
    padding: $white-bubble-padding;
    margin: 30px 46px;
    right: 19px;
    text-align: center;
    bottom: 30px;

    $visual-adjust: 2px;

    &.wrong-date {
      padding-top: $white-bubble-padding - $visual-adjust;
      padding-bottom: $white-bubble-padding + $visual-adjust;
    }
  }

  .choices {
    .survey-input {
      margin-left: 20px;
      height: 1.3em;
      width: 154px;
      position: relative;
      bottom: 2px;
      font-size: 0.6em;
      font-style: italic;
      background-color:white;
      border: 0.05em solid black;
      display: inline-block;
    }
  }

  .get-help {
    position: relative;
    bottom: 65px;
    left: 8px;
  }

  .question-text { font-family: 'pt-sans' }
}
