/* Icons */
$comfort-icon: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='82' height='82'%3E%3Ccircle cx='41' cy='41' r='41' fill='%23e7b284'/%3E%3Cpath d='M82 41c0 22.7-18.4 41-41 41L29.5 55.2l2.7-25.4 18.7-7.7 30.8 13.6.3 5.3z' fill='%23d37528'/%3E%3Cpath d='M51.5 48.7l6.9 2.7c-1.1 4.8-2.9 8.3-5.4 10.5-2.5 2.2-5.6 3.3-9.2 3.3-4.6 0-8.3-1.8-11.2-5.3-3.3-4.1-4.9-9.6-4.9-16.5 0-7.3 1.7-13.1 5-17.2 2.9-3.6 6.7-5.4 11.6-5.4 4 0 7.3 1.3 9.9 4 1.9 1.9 3.3 4.8 4.2 8.5l-7 2.1c-.5-2.3-1.3-4.1-2.7-5.3s-2.9-1.9-4.7-1.9c-2.6 0-4.7 1.1-6.4 3.4s-2.5 6-2.5 11.2c0 5.4.8 9.3 2.4 11.5 1.6 2.3 3.7 3.4 6.2 3.4 1.9 0 3.5-.7 4.9-2.2 1.3-1.3 2.3-3.7 2.9-6.8z' fill='%23fff'/%3E%3C/svg%3E");

$resuscitation-icon: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='82' height='82'%3E%3Ccircle cx='41' cy='41' r='41' fill='%23e7b284'/%3E%3Cpath d='M82 41c0 22.7-18.4 41-41 41L26.6 64.5V23.8l24.9-.7L82 41z' fill='%23d37528'/%3E%3Cpath d='M26.9 64.5v-43h15c3.8 0 6.6.4 8.2 1.2 1.6.8 3 2.1 4 4 1.1 1.9 1.6 4.2 1.6 6.8 0 3.4-.8 6.1-2.4 8.1-1.6 2.1-3.9 3.3-6.9 3.9 1.5 1.1 2.8 2.3 3.8 3.6 1 1.3 2.4 3.6 4.1 7l4.3 8.4H50l-5.1-9.3c-1.9-3.4-3.1-5.5-3.8-6.3-.7-.8-1.4-1.4-2.1-1.8-.7-.3-1.9-.5-3.6-.5H34v17.9h-7.1zM34 39.7h5.3c3.2 0 5.3-.1 6.2-.4.9-.3 1.6-.9 2.1-1.7.5-.9.8-2 .8-3.4s-.3-2.5-.8-3.3c-.5-.8-1.3-1.4-2.2-1.7-.7-.2-2.6-.3-5.8-.3H34v10.8z' fill='%23fff'/%3E%3C/svg%3E");

$ribbon-gray: url("data:image/svg+xml,%3Csvg id='Layer_1' xmlns='http://www.w3.org/2000/svg' height='24' width='12' viewBox='0 0 58 109.4'%3E%3Cstyle%3E.st0%7Bfill:%23c1bdbc%7D%3C/style%3E%3Cpath class='st0' d='M39.1 56.2c-3.6-6.3-7.1-12.2-10-16.8-1.5-2.3-2.8-4.4-3.9-5.9 0-.1-.1-.1-.1-.2-.1-.2-.2-.3-.3-.5C16.2 21.4 13.3 8.5 13.3 8.5c-7.7 14.6-2.9 32.7-2.9 32.7l8.6 15 10 17.4 20.6 35.7L58 89.9s-9.6-17.6-18.9-33.7zM30 37.9c2.9 4.5 6.4 10.4 10 16.6l7.7-13.3s4.8-18.1-2.9-32.7c0 0-2.9 12.9-11.4 24.3-1 1.4-2.1 3.1-3.4 5.1zM0 89.9l8.4 19.5L28 75.3 18 57.8C9 73.4 0 89.9 0 89.9z'/%3E%3Cg%3E%3Cpath class='st0' d='M28.2 0c-5.7.3-11 2.9-13.3 6.7l2.9 9.2c2.6-2.2 6.3-4.3 10.3-4.6V0zM30.2 0v11.3c3.3.1 6.9 1.4 10.4 4.7l2.8-9.5C40 2 35.1.1 30.2 0z'/%3E%3C/g%3E%3C/svg%3E");

$ribbon-color: url("data:image/svg+xml,%3Csvg id='Layer_1' xmlns='http://www.w3.org/2000/svg' height='24' width='12' viewBox='0 0 58 109.4'%3E%3Cstyle%3E.st1%7Bfill:%2300c0e0%7D%3C/style%3E%3Cpath d='M39.1 56.2c-3.6-6.3-7.1-12.2-10-16.8-1.5-2.3-2.8-4.4-3.9-5.9 0-.1-.1-.1-.1-.2-.1-.2-.2-.3-.3-.5C16.2 21.4 13.3 8.6 13.3 8.6c-7.7 14.6-2.9 32.7-2.9 32.7l8.6 15 10 17.4 20.6 35.7L58 89.9s-9.6-17.6-18.9-33.7z' fill='%23f49bc2'/%3E%3Cpath class='st1' d='M30 37.9c2.9 4.5 6.4 10.4 10 16.6l7.7-13.3s4.8-18.1-2.9-32.7c0 0-2.9 12.9-11.4 24.3-1 1.4-2.1 3.1-3.4 5.1zM0 89.9l8.4 19.5L28 75.3 18 57.8C9 73.5 0 89.9 0 89.9z'/%3E%3Cg%3E%3Cpath d='M28.2 0c-5.7.3-11 2.9-13.3 6.7l2.9 9.2c2.6-2.2 6.3-4.3 10.3-4.6V0z' fill='%23f9cbdf'/%3E%3Cpath d='M30.2 0v11.3c3.3.1 6.9 1.4 10.4 4.7l2.8-9.5C40 2.1 35.1.2 30.2 0z' fill='%2396d9ec'/%3E%3C/g%3E%3C/svg%3E");
 
 $foot-dark-blue: url("data:image/svg+xml,%3Csvg id='Layer_1' xmlns='http://www.w3.org/2000/svg' height='24' width='12' viewBox='0 0 54.5 109.8'%3E%3Cstyle%3E.st0%7Bfill:%23007198%7D%3C/style%3E%3Cpath class='st0' d='M19.7 65.7c-.6 2.7-2.3 2.7-3.9 2.9-2.3.4-4.4 2.9-4.5 4-.7 6.3-2 12.2-4.4 18.1-2.6 6.7.8 15.5 6.1 18.3 4.4 2.3 12.5-.5 15.7-5.7 1.8-2.9 3.8-5.9 4.5-9.4 1.4-7.2 2.9-14.4 6-21.1 2.8-6.3 5.5-12.6 7.8-19.1 2.3-6.6 2-6.8-4.7-8.2-1.4-.3-1.5-1.2-1.7-2.2-.2-1 .3-1.4 1.3-1 3.8 1.7 5.2-.9 6-3.7 1-3 .9-5.9-3.1-7.1-2-.6-3.8-1.7-5.6-2.8-.8-.5-1.9-.7-2-2.1-.4-5.3-6.5-9-12-8.3-3.4.4-6 3-9.4 2.8-2.5-.2-4.8-1.5-7.5-1.1-3.9.6-6.2 4.1-5.9 9.2.2 4.1 2.6 5.4 6.3 5.3 1.9-.1 3.8 0 6 0-2 2.8-2 2.8-4 2.8-4.1-.1-5.4 1.8-4 5.6.8 2.2 2.2 3.7 5.6 3.7-4.1 3.2-3 5.8-.8 8.5 1.3 1.6 3.1 3.2 2.8 5.5-.6 4 .7 6 5.4 5.1zM5 14.9c6.5.2 9.5-1.4 9.6-4.6.1-3.6-3.3-6.4-7.7-6.4C3.9 4 0 7.8 0 10.7c.1 4.1 2.9 4.4 5 4.2zM36.1 4.6c-.7.8-2.9.9-2.7 3.2.1 1.9.5 4.3 2.9 4.3 2.1 0 4.1-1.2 4.2-3.8.1-2.3-1.4-3.5-4.4-3.7zM26 0c-1.1 1.2-3.5.9-3.6 3.9-.1 3 2 3.5 3.9 3.8 2.9.5 2.9-2.5 3.4-4.1C30.4 1.2 28.2.8 26 0zm22.8 23.4c-.3 2.8 1.5 4.6 3.4 4.6 1.8 0 2.3-2.6 2.3-4.2s-.4-3.8-2.6-3.4c-1.4.1-3.9.5-3.1 3zm-3.3-6.8c1.1-.4 3.2.7 2.9-2-.2-1.6-.4-3.2-2.3-3.3-2-.2-2.9 1.5-3.2 3.2-.3 1.6.8 2.3 2.6 2.1z'/%3E%3Cpath class='st0' d='M5 14.9c-2.1.1-4.9-.2-5-4.2C0 7.8 3.9 4 6.8 4c4.4 0 7.8 2.8 7.7 6.4-.1 3.1-3 4.7-9.5 4.5zM36.1 4.6c3 .2 4.5 1.4 4.4 3.7-.1 2.6-2.1 3.8-4.2 3.8-2.5 0-2.8-2.4-2.9-4.3-.2-2.3 2-2.4 2.7-3.2zM26 0c2.2.8 4.4 1.2 3.7 3.6-.5 1.7-.5 4.6-3.4 4.1-1.9-.3-4-.8-3.9-3.8C22.6.9 25 1.2 26 0zM48.8 23.4c-.7-2.6 1.8-2.9 3.1-3.1 2.1-.3 2.6 1.9 2.6 3.4 0 1.7-.4 4.3-2.3 4.2-2 .1-3.8-1.7-3.4-4.5zM45.5 16.6c-1.8.2-2.9-.4-2.6-2.2.3-1.6 1.1-3.3 3.2-3.2 1.9.1 2.1 1.7 2.3 3.3.3 2.8-1.8 1.7-2.9 2.1z'/%3E%3C/svg%3E");

$foot-light-blue: url("data:image/svg+xml,%3Csvg id='Layer_1' xmlns='http://www.w3.org/2000/svg' height='24' width='12' viewBox='0 0 54.5 109.8'%3E%3Cstyle%3E.st0%7Bfill:%2311becd%7D%3C/style%3E%3Cpath class='st0' d='M19.7 65.7c-.6 2.7-2.3 2.7-3.9 2.9-2.3.4-4.4 2.9-4.5 4-.7 6.3-2 12.2-4.4 18.1-2.6 6.7.8 15.5 6.1 18.3 4.4 2.3 12.5-.5 15.7-5.7 1.8-2.9 3.8-5.9 4.5-9.4 1.4-7.2 2.9-14.4 6-21.1 2.8-6.3 5.5-12.6 7.8-19.1 2.3-6.6 2-6.8-4.7-8.2-1.4-.3-1.5-1.2-1.7-2.2-.2-1 .3-1.4 1.3-1 3.8 1.7 5.2-.9 6-3.7 1-3 .9-5.9-3.1-7.1-2-.6-3.8-1.7-5.6-2.8-.8-.5-1.9-.7-2-2.1-.4-5.3-6.5-9-12-8.3-3.4.4-6 3-9.4 2.8-2.5-.2-4.8-1.5-7.5-1.1-3.9.6-6.2 4.1-5.9 9.2.2 4.1 2.6 5.4 6.3 5.3 1.9-.1 3.8 0 6 0-2 2.8-2 2.8-4 2.8-4.1-.1-5.4 1.8-4 5.6.8 2.2 2.2 3.7 5.6 3.7-4.1 3.2-3 5.8-.8 8.5 1.3 1.6 3.1 3.2 2.8 5.5-.6 4 .7 6 5.4 5.1zM5 14.9c6.5.2 9.5-1.4 9.6-4.6.1-3.6-3.3-6.4-7.7-6.4C3.9 4 0 7.8 0 10.7c.1 4.1 2.9 4.4 5 4.2zM36.1 4.6c-.7.8-2.9.9-2.7 3.2.1 1.9.5 4.3 2.9 4.3 2.1 0 4.1-1.2 4.2-3.8.1-2.3-1.4-3.5-4.4-3.7zM26 0c-1.1 1.2-3.5.9-3.6 3.9-.1 3 2 3.5 3.9 3.8 2.9.5 2.9-2.5 3.4-4.1C30.4 1.2 28.2.8 26 0zm22.8 23.4c-.3 2.8 1.5 4.6 3.4 4.6 1.8 0 2.3-2.6 2.3-4.2s-.4-3.8-2.6-3.4c-1.4.1-3.9.5-3.1 3zm-3.3-6.8c1.1-.4 3.2.7 2.9-2-.2-1.6-.4-3.2-2.3-3.3-2-.2-2.9 1.5-3.2 3.2-.3 1.6.8 2.3 2.6 2.1z'/%3E%3Cpath class='st0' d='M5 14.9c-2.1.1-4.9-.2-5-4.2C0 7.8 3.9 4 6.8 4c4.4 0 7.8 2.8 7.7 6.4-.1 3.1-3 4.7-9.5 4.5zM36.1 4.6c3 .2 4.5 1.4 4.4 3.7-.1 2.6-2.1 3.8-4.2 3.8-2.5 0-2.8-2.4-2.9-4.3-.2-2.3 2-2.4 2.7-3.2zM26 0c2.2.8 4.4 1.2 3.7 3.6-.5 1.7-.5 4.6-3.4 4.1-1.9-.3-4-.8-3.9-3.8C22.6.9 25 1.2 26 0zM48.8 23.4c-.7-2.6 1.8-2.9 3.1-3.1 2.1-.3 2.6 1.9 2.6 3.4 0 1.7-.4 4.3-2.3 4.2-2 .1-3.8-1.7-3.4-4.5zM45.5 16.6c-1.8.2-2.9-.4-2.6-2.2.3-1.6 1.1-3.3 3.2-3.2 1.9.1 2.1 1.7 2.3 3.3.3 2.8-1.8 1.7-2.9 2.1z'/%3E%3C/svg%3E");

     $foot-green: url("data:image/svg+xml,%3Csvg id='Layer_1' xmlns='http://www.w3.org/2000/svg' height='24' width='12' viewBox='0 0 54.5 109.8'%3E%3Cstyle%3E.st0%7Bfill:%23006937%7D%3C/style%3E%3Cpath class='st0' d='M19.7 65.7c-.6 2.7-2.3 2.7-3.9 2.9-2.3.4-4.4 2.9-4.5 4-.7 6.3-2 12.2-4.4 18.1-2.6 6.7.8 15.5 6.1 18.3 4.4 2.3 12.5-.5 15.7-5.7 1.8-2.9 3.8-5.9 4.5-9.4 1.4-7.2 2.9-14.4 6-21.1 2.8-6.3 5.5-12.6 7.8-19.1 2.3-6.6 2-6.8-4.7-8.2-1.4-.3-1.5-1.2-1.7-2.2-.2-1 .3-1.4 1.3-1 3.8 1.7 5.2-.9 6-3.7 1-3 .9-5.9-3.1-7.1-2-.6-3.8-1.7-5.6-2.8-.8-.5-1.9-.7-2-2.1-.4-5.3-6.5-9-12-8.3-3.4.4-6 3-9.4 2.8-2.5-.2-4.8-1.5-7.5-1.1-3.9.6-6.2 4.1-5.9 9.2.2 4.1 2.6 5.4 6.3 5.3 1.9-.1 3.8 0 6 0-2 2.8-2 2.8-4 2.8-4.1-.1-5.4 1.8-4 5.6.8 2.2 2.2 3.7 5.6 3.7-4.1 3.2-3 5.8-.8 8.5 1.3 1.6 3.1 3.2 2.8 5.5-.6 4 .7 6 5.4 5.1zM5 14.9c6.5.2 9.5-1.4 9.6-4.6.1-3.6-3.3-6.4-7.7-6.4C3.9 4 0 7.8 0 10.7c.1 4.1 2.9 4.4 5 4.2zM36.1 4.6c-.7.8-2.9.9-2.7 3.2.1 1.9.5 4.3 2.9 4.3 2.1 0 4.1-1.2 4.2-3.8.1-2.3-1.4-3.5-4.4-3.7zM26 0c-1.1 1.2-3.5.9-3.6 3.9-.1 3 2 3.5 3.9 3.8 2.9.5 2.9-2.5 3.4-4.1C30.4 1.2 28.2.8 26 0zm22.8 23.4c-.3 2.8 1.5 4.6 3.4 4.6 1.8 0 2.3-2.6 2.3-4.2s-.4-3.8-2.6-3.4c-1.4.1-3.9.5-3.1 3zm-3.3-6.8c1.1-.4 3.2.7 2.9-2-.2-1.6-.4-3.2-2.3-3.3-2-.2-2.9 1.5-3.2 3.2-.3 1.6.8 2.3 2.6 2.1z'/%3E%3Cpath class='st0' d='M5 14.9c-2.1.1-4.9-.2-5-4.2C0 7.8 3.9 4 6.8 4c4.4 0 7.8 2.8 7.7 6.4-.1 3.1-3 4.7-9.5 4.5zM36.1 4.6c3 .2 4.5 1.4 4.4 3.7-.1 2.6-2.1 3.8-4.2 3.8-2.5 0-2.8-2.4-2.9-4.3-.2-2.3 2-2.4 2.7-3.2zM26 0c2.2.8 4.4 1.2 3.7 3.6-.5 1.7-.5 4.6-3.4 4.1-1.9-.3-4-.8-3.9-3.8C22.6.9 25 1.2 26 0zM48.8 23.4c-.7-2.6 1.8-2.9 3.1-3.1 2.1-.3 2.6 1.9 2.6 3.4 0 1.7-.4 4.3-2.3 4.2-2 .1-3.8-1.7-3.4-4.5zM45.5 16.6c-1.8.2-2.9-.4-2.6-2.2.3-1.6 1.1-3.3 3.2-3.2 1.9.1 2.1 1.7 2.3 3.3.3 2.8-1.8 1.7-2.9 2.1z'/%3E%3C/svg%3E");

      $foot-gray: url("data:image/svg+xml,%3Csvg id='Layer_1' xmlns='http://www.w3.org/2000/svg' height='24' width='12' viewBox='0 0 54.5 109.8'%3E%3Cstyle%3E.st0%7Bfill:%23c1bdbc%7D%3C/style%3E%3Cpath class='st0' d='M19.7 65.7c-.6 2.7-2.3 2.7-3.9 2.9-2.3.4-4.4 2.9-4.5 4-.7 6.3-2 12.2-4.4 18.1-2.6 6.7.8 15.5 6.1 18.3 4.4 2.3 12.5-.5 15.7-5.7 1.8-2.9 3.8-5.9 4.5-9.4 1.4-7.2 2.9-14.4 6-21.1 2.8-6.3 5.5-12.6 7.8-19.1 2.3-6.6 2-6.8-4.7-8.2-1.4-.3-1.5-1.2-1.7-2.2-.2-1 .3-1.4 1.3-1 3.8 1.7 5.2-.9 6-3.7 1-3 .9-5.9-3.1-7.1-2-.6-3.8-1.7-5.6-2.8-.8-.5-1.9-.7-2-2.1-.4-5.3-6.5-9-12-8.3-3.4.4-6 3-9.4 2.8-2.5-.2-4.8-1.5-7.5-1.1-3.9.6-6.2 4.1-5.9 9.2.2 4.1 2.6 5.4 6.3 5.3 1.9-.1 3.8 0 6 0-2 2.8-2 2.8-4 2.8-4.1-.1-5.4 1.8-4 5.6.8 2.2 2.2 3.7 5.6 3.7-4.1 3.2-3 5.8-.8 8.5 1.3 1.6 3.1 3.2 2.8 5.5-.6 4 .7 6 5.4 5.1zM5 14.9c6.5.2 9.5-1.4 9.6-4.6.1-3.6-3.3-6.4-7.7-6.4C3.9 4 0 7.8 0 10.7c.1 4.1 2.9 4.4 5 4.2zM36.1 4.6c-.7.8-2.9.9-2.7 3.2.1 1.9.5 4.3 2.9 4.3 2.1 0 4.1-1.2 4.2-3.8.1-2.3-1.4-3.5-4.4-3.7zM26 0c-1.1 1.2-3.5.9-3.6 3.9-.1 3 2 3.5 3.9 3.8 2.9.5 2.9-2.5 3.4-4.1C30.4 1.2 28.2.8 26 0zm22.8 23.4c-.3 2.8 1.5 4.6 3.4 4.6 1.8 0 2.3-2.6 2.3-4.2s-.4-3.8-2.6-3.4c-1.4.1-3.9.5-3.1 3zm-3.3-6.8c1.1-.4 3.2.7 2.9-2-.2-1.6-.4-3.2-2.3-3.3-2-.2-2.9 1.5-3.2 3.2-.3 1.6.8 2.3 2.6 2.1z'/%3E%3Cpath class='st0' d='M5 14.9c-2.1.1-4.9-.2-5-4.2C0 7.8 3.9 4 6.8 4c4.4 0 7.8 2.8 7.7 6.4-.1 3.1-3 4.7-9.5 4.5zM36.1 4.6c3 .2 4.5 1.4 4.4 3.7-.1 2.6-2.1 3.8-4.2 3.8-2.5 0-2.8-2.4-2.9-4.3-.2-2.3 2-2.4 2.7-3.2zM26 0c2.2.8 4.4 1.2 3.7 3.6-.5 1.7-.5 4.6-3.4 4.1-1.9-.3-4-.8-3.9-3.8C22.6.9 25 1.2 26 0zM48.8 23.4c-.7-2.6 1.8-2.9 3.1-3.1 2.1-.3 2.6 1.9 2.6 3.4 0 1.7-.4 4.3-2.3 4.2-2 .1-3.8-1.7-3.4-4.5zM45.5 16.6c-1.8.2-2.9-.4-2.6-2.2.3-1.6 1.1-3.3 3.2-3.2 1.9.1 2.1 1.7 2.3 3.3.3 2.8-1.8 1.7-2.9 2.1z'/%3E%3C/svg%3E");