@import '../../scss/colors';
@import '../../scss/icons';

.mild {
  .coloredHeading { color: $light-blue }

  .show .ia-container {
    .iaGrid .elem.mild { background-image: $foot-light-blue;}
    .legend h6.mild { display: block;}
  }
}