@import '../../scss/colors';
@import '../../scss/icons';

.iaGrid {
  display: flex;
  flex-direction: column;
  height: 300px;
  margin: 14px auto 0;
  width: 300px;

  .row {
    display: flex;
    flex: 1;
  }

  .elem {
    background-position: center;
    background-repeat: no-repeat;
    background-size: auto;
    flex: 1;
    margin: 1%;
    opacity: 0;
  }
}

.elem.mild { background-image: $foot-light-blue; }
.elem.moderate { background-image: $foot-dark-blue; }
.elem.severe { background-image: $foot-green; }
.elem.nonSurvival { background-image: $ribbon-color; }

.legend {
  margin-top: 18px;
  margin-left: 20px;
}

.legend h6 {
  
  background-repeat: no-repeat;
  background-size: auto;
  font-size: 0.9em;
  line-height: 1.6em;
  padding: 0.2em 0 0.3em 0;
  margin: 0 0 0 0.5em;
  opacity: 0;
  
}

.legend h6 {
  &.mild { background-image: $foot-light-blue; }
  &.moderate { background-image: $foot-dark-blue; }
  &.severe { background-image: $foot-green; }
  &.nonSurvival {
    background-image: $ribbon-color;

  }
  &.total {
    background-image: $foot-gray;

  }
}

.right .legend h6.nonSurvival {
  background-position-x: 1.5em;
  padding-left: 3.7em;
}

// .left.show .

.left.show .elem.mild { animation: 12s 1s forwards mild-fadein; }
@keyframes mild-fadein {
  from { background-image: $foot-gray; opacity: 0; }
  9% { opacity: 1 }
  18% { background-image: $foot-gray; }
  27% { background-image: $foot-light-blue; }
  36% { background-image: $foot-light-blue; }
  45% { background-image: $foot-gray; }
  90% { background-image: $foot-gray; }
  99% { background-image: $foot-light-blue; }
  to { background-image: $foot-light-blue; opacity: 1; }
}

.left.show .elem.moderate { animation: 12s 1s forwards moderate-fadein; }
@keyframes moderate-fadein {
  from { background-image: $foot-gray; opacity: 0; }
  9% { background-image: $foot-gray; opacity: 1 }
  36% { background-image: $foot-gray; }
  45% { background-image: $foot-dark-blue; }
  54% { background-image: $foot-dark-blue; }
  63% { background-image: $foot-gray; }
  90% { background-image: $foot-gray; }
  99% { background-image: $foot-dark-blue; }
  to { background-image: $foot-dark-blue; opacity: 1; }
}

.left.show .elem.severe { animation: 12s 1s forwards severe-fadein; }
@keyframes severe-fadein {
  from { background-image: $foot-gray; opacity: 0; }
  9% { background-image: $foot-gray; opacity: 1 }
  54% { background-image: $foot-gray; }
  63% { background-image: $foot-green; }
  72% { background-image: $foot-green; }
  81% { background-image: $foot-gray; }
  90% { background-image: $foot-gray; }
  99% { background-image: $foot-green; }
  to { background-image: $foot-green; opacity: 1; }
}

.left.show .elem.nonSurvival { animation: 12s 1s forwards nonSurvival-fadein; }
@keyframes nonSurvival-fadein {
  from { background-image: $ribbon-gray; opacity: 0; }
  9% { background-image: $ribbon-gray; opacity: 1 }
  72% { background-image: $ribbon-gray; }
  81% { background-image: $ribbon-color; }
  to { background-image: $ribbon-color; opacity: 1; }
}

.left.show h6.mild { animation: 12s 1s forwards mild-legend-fadein; }
@keyframes mild-legend-fadein {
  from { color: #5F5E5D; background-image: $foot-light-blue; opacity: 0; }
  18% { opacity: 0; }
  27% { opacity: 1 }
  36% { color: #5F5E5D; background-image: $foot-light-blue; }
  45% { color: #C1BEBD; background-image: $foot-gray; }
  90% { color: #C1BEBD; background-image: $foot-gray; }
  99% { color: #5F5E5D; background-image: $foot-light-blue; }
  to { color: #5F5E5D; background-image: $foot-light-blue; opacity: 1; }
}

.left.show h6.moderate { animation: 12s 1s forwards moderate-legend-fadein; }
@keyframes moderate-legend-fadein {
  from { color: #5F5E5D; background-image: $foot-dark-blue; opacity: 0; }
  36% { opacity: 0; }
  45% { opacity: 1; }
  54% { color: #5F5E5D; background-image: $foot-dark-blue; }
  63% { color: #C1BEBD; background-image: $foot-gray; }
  90% { color: #C1BEBD; background-image: $foot-gray; }
  99% { color: #5F5E5D; background-image: $foot-dark-blue; }
  to { color: #5F5E5D; background-image: $foot-dark-blue; opacity: 1; }
}

.left.show h6.severe { animation: 12s 1s forwards severe-legend-fadein; }
@keyframes severe-legend-fadein {
  from { color: #5F5E5D; background-image: $foot-green; opacity: 0; }
  54% { opacity: 0; }
  63% { opacity: 1; }
  72% { color: #5F5E5D; background-image: $foot-green; }
  81% { color: #C1BEBD; background-image: $foot-gray; }
  90% { color: #C1BEBD; background-image: $foot-gray; }
  99% { color: #5F5E5D; background-image: $foot-green; }
  to { color: #5F5E5D; background-image: $foot-green; opacity: 1; }
}

.left.show h6.nonSurvival { animation: 12s 1s forwards nonSurvival-legend-fadein; }
@keyframes nonSurvival-legend-fadein {
  from { opacity: 0; }
  72% { opacity: 0; }
  81% { opacity: 1; }
  to { opacity: 1; }
}

.left.show h6.total { animation: 12s 1s forwards total-legend-fadein; }
@keyframes total-legend-fadein {
  from { opacity: 0; }
  9% { opacity: 0; }
  18% { opacity: 1; }
  to { opacity: 1; }
}

.right.show .elem.nonSurvival { animation: 3s 1s forwards right-nonSurvival-fadein; }
@keyframes right-nonSurvival-fadein {
  from { background-image: $ribbon-gray; opacity: 0; }
  33% { background-image: $ribbon-gray; opacity: 1 }
  66% { background-image: $ribbon-gray; opacity: 1 }
  to { background-image: $ribbon-color; opacity: 1; }
}

.right.show h6.nonSurvival { animation: 3s 1s forwards right-nonSurvival-legend-fadein; }
@keyframes right-nonSurvival-legend-fadein {
  from { opacity: 0; }
  66% { opacity: 0; }
  to { opacity: 1; }
}
