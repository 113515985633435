@import '../../scss/colors';

.en .valuessurvey1 .scale-left { margin-left: 54%; }
.en .valuessurvey1 .scale-right { margin-left: 15%; }
.en .valuessurvey2 .scale-left { margin-left: 54%; }
.en .valuessurvey2 .scale-right { margin-left: 15%; }

.es .valuessurvey1 .scale-left { margin-left: 53%; }
.es .valuessurvey1 .scale-right { margin-left: 17%; }
.es .valuessurvey2 .scale-left { margin-left: 52%; }
.es .valuessurvey2 .scale-right { margin-left: 14.5%; }

.valuessurvey1, .valuessurvey2 {
  article { margin-bottom: 1em;}

  .hints-and-scale {
    position: relative;
    width: 100%;

    .scale {
      font-family: 'pt-sans';
      font-size: 0.75em;
      position: absolute;
      top: 15px;
      width: 100%;

      .scale-dont-know {
        position: absolute;
        top: 18px;
        right: 6px;
        width: 5%;
        text-align: center;
        font-weight: bold;
        letter-spacing: 0;
      }
    }

    .survey-scores {
      box-sizing: border-box;
      display: flex;
      font-family: 'pt-serif';
      font-size: 0.9em;
      font-weight: bold;
      justify-content: space-between;
      padding:  0 10.3% 0 59%;
      position: absolute;
      top: 36px;
      width: 100%;
    }
  }

  .container {
    .left h5 { letter-spacing: 0; }

    .left .left-item-container, .right .values-question { height: 46px; }
  }
}

.valuessurvey1 {
  .hints-and-scale .scale .scale-dont-know { top: 21px; }

  .container {
    margin-top: 50px;

    .right .ticks { top: 4px; }
  }
}

.valuessurvey2 .container {
  margin-top: 45px;

  .left .left-item-container, .right .values-question { height: 55px; }
}

.hints-container {
  top: 90px;
  left: 210px;
}

.hints .green-arrow {
  position: relative;
  bottom: 0.5vh;
}
