@import '../../scss/colors';

.intro {
  padding-right: 21px;
  nav {
    position: static;
    width: 100%;
    margin-top: 5px;
  }

  h2 { margin-top: 200px }

  p {
    margin-top: 5px;

    &:nth-of-type(1) { margin-top: 10px;}
  }

}
