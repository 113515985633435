@import '../../scss/colors';
@import '../../scss/icons';

/* <Outcomes> styling */

.en .outcomes h2 { margin-top: 98px; }
.es .outcomes h2 { margin-top: 58px; }

.en .outcomes section { height: 176px; }
.es .outcomes section { height: 176px; }

.outcomes .container .outcomes-intro {
  margin-top: 19px;
  height: 54px;
}

.es .legend { margin-top: 9px; }

.en .legend h6 { 
  padding-left: 4.2em;
  background-position-x: 2.4em;
 }

.es .legend h6 {
  font-size: 0.8em;
  padding-left: 5em;
  background-position-x: 2.7em;
}

.en .legend {
  h6.total, h6.nonSurvival {
    background-position-x: 0.4em;
    padding-left: 2.4em;
  }
}

.es .legend {
  h6.total, h6.nonSurvival {
    background-position-x: 0.4em;
    padding-left: 2.4em;
  }
}

.en .right .legend h6.nonSurvival {
  background-position-x: 0;
  padding-left: 2em;
  margin-left: 0.3em;
}

.es .right .legend h6.nonSurvival {
  background-position-x: 0;
  padding-left: 2.35em;
  margin-left: 0.35em;
}

.outcomes {
  h2 {
    margin-left: 26px;
    letter-spacing: 0;
  }

  h3 {
    background-repeat: no-repeat;
    background-size: contain;
    background-position: left;
    color: $orange;
    margin-bottom: 5px;
    margin-top: 8px;
    font-size: 19px;
    line-height: 24px;
    padding-left: 26px;
  }

  h4 {
    font-size: 1em;
    opacity: 0;
    letter-spacing: 0;
    // text-align: center;
    margin: auto;
  }

  .show {
    h4 {
      opacity: 1;
      transition: opacity 1s;
    }
  }

  button {
    padding: 3px 6px;
    margin-top: auto;
    align-self: flex-start;
    line-height: 1.25;
  }

  .pressedButton {
    background: #676767;
  }

  article { padding: 0 40px 0 48px; }

  .container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 18px;

    .left {
      flex-grow: 1.05;
      h4 {
        width: 80%;
        white-space: normal;
      }
    }
    .right {
      flex-grow: 1;
      h4 { 
        width: 85%;
        white-space: normal;
      }
      .graph .legend {
        margin-right: 20px;
      }
    }

    .left, .right {
      
      flex-basis: 0;

      section {
        padding: 0 24px;
        position: relative;
        animation: fadein 1s;
        -webkit-animation: fadein 1s;
        display: flex;
        flex-direction: column;
      }

    }
  }

  .resuscitation h3 { background-image: $resuscitation-icon; }
  .comfort-care h3 { background-image: $comfort-icon; }
}
