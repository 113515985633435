@import '../scss/colors';
@import '../scss/icons';

/* for debugging only */

// .app { color: red }

// nav div .nav-right, nav div .nav-left, .survey ::placeholder  {
//   color: red;
// }

// .options section h4, .survey input, .top-right-corner .orange-text {
//   color: red;
// }

// .app .hands .intro .footer, .radio-button-custom, .survey .survey-input,
// .survey .radio-button-custom, .survey select{
//   background-color: lightgreen;
//   border-color: lightgreen;
// }

// .severity .content article .coloredHeading { color: red }
// .severity .content .video-text .coloredHeading { color: red }

// .plain3 .hints-and-scale h6 { color: red }

// .survey .error { color: red}

// .summary .summary-choices-container .summary-choices {
//   .summary-factors ,.summary-quality-of-life {
//     p span:nth-of-type(1){ color: maroon; }
//   }
// }

// .valuessurvey1 .content .hints-and-scale h6:nth-of-type(1) {color: red};

/* CSS reset */

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym,
address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var,
b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead,
tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav,
output, ruby, section, summary, time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    vertical-align: baseline;}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {display: block;}

body {
  background-color: black;
  line-height: 1;
}

blockquote, q {quotes: none;}

* {
  outline: none;
}

/* General styling */

h1, h2, h3, h4, h5, h6 {
  font-family: 'pt-sans';
  letter-spacing: 0.045em;
  line-height: 1.1;
  font-weight: bold;
}

h2 { font-size: 2.4em; }

h3 { font-size: 1em; }

h4 { font-size: 1.2em;}

h5 { font-size: 0.825em; }

h6 { font-size: 0.75em; }

p, li, form, input {
  font-family: 'pt-serif';
  font-size: 1.075em;
  line-height: 1.25;
}

%anchor-style {
  font-family: 'pt-serif';
  font-size: 0.9em;
}

a {
  @extend %anchor-style;
}


body { color: $dark-gray;}

@keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

@-webkit-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

@keyframes fadeout {
  from { opacity: 1; }
  to   { opacity: 0; }
}

@-webkit-keyframes fadeout {
  from { opacity: 1; }
  to   { opacity: 0; }
}

.inactive { display: none }

.fade-out {
  opacity: 0;
  animation: fadeout 1s;
  -webkit-animation: fadeout 1s;
}

.white-bubble {
  background-color: white;
  opacity: 0.4;
  border: 0.1em solid white;
  border-radius: 0.5em;
  color: black;
}

.hidden { visibility: hidden;}

.app {
  position: relative;
  white-space: pre-wrap;
  width: 100%;
  background-color: black;
  display: flex;
  align-items: center;
}

.content {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.background {
  background-size: 100% 100%;
  box-sizing: border-box;
  position: relative;
  margin: 0 auto;
  height: 1004px;
  width: 768px;

  &.plain, &.plain2, &.plain3 {
    background-image: radial-gradient(circle at 68% 68%, rgb(229, 224, 211), rgb(236, 215, 191) 90%);
  }
  &.mild { background-image: radial-gradient(circle at 68% 68%, rgb(236, 249, 249), rgb(194, 223, 222) 90%); }
  &.moderate { background-image: radial-gradient(circle at 68% 68%, rgb(229, 245, 251), rgb(184, 218, 231) 90%); }
  &.severe { background-image: radial-gradient(circle at 68% 68%, rgb(241, 252, 246), rgb(204, 238, 220) 90%); }
  &.nonsurvival, &.comfortcare {
    background-image: radial-gradient(circle at 68% 68%, rgb(246, 245, 253), rgb(225, 222, 244) 90%);
  }
}

%nav-text-style {
  text-decoration: none;
  color: #5F5E5D;
  font-style: italic;
  font-weight: bold;
  letter-spacing: 0.03em;
}

nav {
  position: absolute;
  width: 630px;
  left: 69px;
  bottom: 55px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .nav-text{
    @extend %nav-text-style;
  }

  .nav-triangle {
    margin-top: 2.7vh;
    height: 0.75em;
  }

  .AudioManager button {
    padding: 0;
    border: 0;
    cursor: pointer;
    background: transparent;
    position: relative;
    top: 12px;
    display: none;
    height: 40px;

    &.visible { display: block; }

    img {
      width: 40px;
      height: 40px;
    }
  }

  .flex-placeholder {
    width: 48px;
    height: 40px;
  }
}

.reset-button {
  @extend %nav-text-style;
  @extend %anchor-style;
  text-decoration: underline;
  padding: 10px 8px 10px 0;
  position: absolute;
  bottom: 250px;
}

.orange-text {
  color: $orange;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: left;

  &.resuscitation {background-image: $resuscitation-icon; }
  &.comfort-care {background-image: $comfort-icon;}
}

.flipped { transform: scaleX(-1); }

.cover-fpo { background-image: url('../images/Cover_fpo.png');}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: black;
    opacity: 1; /* Firefox */
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: black;
}

::-ms-input-placeholder { /* Microsoft Edge */
    color: black;
}




@media print {
  .app {
    page-break-after: avoid;
    overflow: hidden;
    height: 95vh;
    width: 100vw;
    .hidden-print {
      display: none !important;
    }
    .show-print-only {
      display: block !important;
      .rule { margin-bottom: 3vh;}
    }
    .plain3 {
      background: white;
      padding-top: 2vh !important;
    }
    .elem {
      -webkit-print-color-adjust: exact;
      color-adjust: exact;
    }
    .rule { margin-top: 2vh !important;}
    .summary-outcomes { margin-top: 0.75vh !important}
    // div {border: 1px solid black}
  }
}

/* PageType styling */

.hands {
  padding: 0 100px 60px 100px;
  background-image: url('../images/Hands_blurred.jpg');
}

.plain { padding: 0 95px 60px 100px;}

.plain3 {padding: 95px 60px 60px 90px;}

/* <Mild><Moderate><Severe><NonSurvival> styling */

$severity-padding-left: 61px;
$severity-padding-right: 31px;

.severity {
  padding: 25px $severity-padding-right 60px $severity-padding-left;
  position: relative;

  ul {
   font-size: 0.87em;
   // margin: 9px 138px 0 15px;
   margin: 5px 0 0 18px;
  }

  article {
    width: 100%;

    h2 {
      margin: 88px 0 18px 0;
      font-size: 1.8em;
      letter-spacing: 0.02em;
      line-height: 1.25;
    }
  }

  h3 {
    font-family: 'pt-serif';
    font-size: 1.05em;
    letter-spacing: 0.02em;
    margin-bottom: 0.3em;
    line-height: 1.2em;
  }

  li { line-height: 1.22;}

  p {
  font-size: 0.92em;
  line-height: 1.25;
  letter-spacing: 0.01em;
  }

  section {
    animation: fadein 1s;
    -webkit-animation: fadein 1s;
  }

  .iaGrid .elem { background-size: contain }

  .spacer {
    width: 100%;
    height: 15px;
  }
  .orange-text { padding-left: 19px; }

  .video-text {
    font-size: 1em;
    width: 177px;
    margin: 5px 0 0 12px;
    animation: fadein 2s;
    -webkit-animation: fadein 2s;
  }

  .video-and-text {
    display: flex;
    padding-top: 20px;
    justify-content: space-between;
    align-self: flex-start;
    width: 100%;
  }

  .video {
   background-color: black;
   display: inline-block;
   min-width: 300px;
  }

  .top-right-corner {
   display:block;
   position: absolute;
   top:40px;
   right: 40px;
   font-size: 1.25em;
   letter-spacing: 0;
  }

  .ia-container {
    float: right;
   // position: absolute;
   // top: 203px;
   // right: 40px;

    .iaGrid {
      width: 167px;
      height: 167px;
      margin: 0 0 0 auto;

      .elem {
        opacity: 1;
        &.mild,
        &.severe,
        &.moderate { background-image: $foot-gray}
        &.nonSurvival {background-image: $ribbon-gray;}
      }
    }

    .legend {
      margin: 4px auto;
      text-align: center;

      h6 {
        display: none;
        background: none;
        opacity: 1;
        margin: 0 auto;
        padding: 0;
      }
    }
  }
}

.valuessurvey {

  .head-note {
    margin-top: 10px;
    margin-right: 22px;
    p {
      margin-bottom: 10px;
      letter-spacing: 0.01em;
    }
  }

  .container {
    display: flex;
    width: 100%;

    .left, .right {
      flex: 1;
      width: 50%;
      display: inline-block;
    };

    .left {
      .left-item-container {
        padding-right: 1%;
        display: flex;
        flex-direction: column;
        justify-content: center;

        h5 { font-size: 13px;}

        input {
          display: block;
          font-size: 0.5em;
          width: 284px;
        }
      }
    }

    .right {
      $slider-width: 85%;

      padding: 0 0 0 3.5%;

      .values-question {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .I-dont-know-container {
          box-sizing: border-box;
          display: flex;
          margin: 0 9px 0 21px;
        }

        $radio-button-size: 17px;
        $radio-button-border: 1px;
        $radio-inner-circle: 10px;

        .radio-enlarge {
          padding: 11px 8px;
          display: flex;
          align-items: center;
          justify-items: center;

          .radio-button-custom {
            height: $radio-button-size - $radio-button-border;
            width: $radio-button-size - $radio-button-border;
            border-radius: 50%;
            border: $radio-button-border solid black;
            position: relative;
            box-sizing: border-box;

            .inner-circle {
              height: $radio-inner-circle;
              width:$radio-inner-circle;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              background-color: $dark-gray;
              display: block;
              position: absolute;
              border-radius: 50%;
            }
          }
        }
      }
    }
  }
}



.pressedButton, .green-button {
  border-radius: 0.4em;
  color: #E7EDDC;
  border-color: $button-border-green;
  background-image: linear-gradient(to right, #96BA64, $button-green);
  font-family: 'pt-sans', sans-serif;
  font-size: 14px;
  font-weight: bold;
}

// sliders
.slider-container {
  // cross browser reset
  input[type=range]:focus { outline: none;}
  input[type=range]::-ms-track {
    width: 100%;
    cursor: pointer;
    background: transparent;
    border-color: transparent;
    color: transparent;
  }

  -webkit-tap-highlight-color: transparent;

  $slider-height: 8px;
  $slider-handle-width:  2 * $slider-height;
  $slider-handle-height: 1.4 * $slider-handle-width;
  $slider-input-border: 1px;

  input[type=range] {

    background: transparent;
    outline: none;
    -webkit-appearance: none; /* Hides the slider so that custom slider can be made */
    position: relative;
    overflow: hidden;
    height: 1.05 * $slider-handle-height;
    width: 100%;
    // z-index: 1 ;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;


    @function makelongshadow($color){
      // $val: 0.35em 0 0 -0.3em $color;
      $val: 0.5em 0 0 -0.41em $color;
      @for $i from 10 through 255 {
        $val: #{$val}, #{$i * 0.055}em 0 0 -0.41em $color;
      }
      @return $val;
    }

    &::-webkit-slider-thumb {
      -webkit-appearance: none;
      width: $slider-handle-width;
      height: $slider-handle-height;
      background: #ffffff;
      box-shadow: makelongshadow($dark-gray);
      border: solid 1px $dark-gray;
      border-radius: 0.5 * $slider-handle-width;
      position: relative;
      bottom: 0.99 * $slider-height;
    }

    &::-webkit-slider-runnable-track {
      background: $slider-green;
      height: $slider-height;
      pointer-events: none;
      border: solid $slider-input-border $dark-gray;
      border-radius: 0.5 * $slider-height;
    }
  }

  flex: 1;
  position: relative;
  $slider-width: auto;

  box-sizing: border-box;
  width: $slider-width;
  display: flex;
  align-items: center;


  $clickable-tick-width: 19px;
  $clickable-tick-height: 11px;
  $tick-margin: 43px;
  $tick-width: 2px;
  $tick-height: 15px;

  .slider-round-end {
    position: absolute;
    height:  $slider-height;
    width: 0.5 *$slider-height;
    z-index: 10;
    background-color: $dark-gray;
    right: -1px;
    border: 0.5 * $slider-height #ecd7bf;
    border-left: none;
    border-bottom-right-radius: 0.5*$slider-height;
    border-top-right-radius: 0.5*$slider-height;
    box-sizing: border-box;
  }

  .ticks {
    position: absolute;
    left: 10px;
    padding-left: 23px;

    display: flex;
    justify-content: space-between;
    width: 100%;
    box-sizing: border-box;

    .clickable-tick{
      min-height: ($clickable-tick-height) *2 + $tick-height;
      min-width: ($clickable-tick-width) *2 + $tick-width;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 10;

      .tick {
        width: $tick-width;
        height: $tick-height;
        background-color:$dark-gray;
      }
    }
  }

  .hints-and-scale {
    h6 {
      font-size: 0.7em;
      letter-spacing: 0;
      color: $slider-green;
    }
  }
}

.markdown {
  white-space: normal;
}

.hints-container {
  position: absolute;
  color: $slider-green;
  letter-spacing: 0;
  .hints .green-arrow {
    height: 15px;
    width: auto;
  }
}

//
