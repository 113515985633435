@import '../../scss/icons';

.en .options h2 { margin-top: 125px; }
.es .options h2 { margin-top: 50px; }
.en .foot-note { margin-top: 33px; }
.es .foot-note { margin-top: 20px; }

.options {
  ul {
    padding: 0;
    letter-spacing: 0.01em;

    li { margin-top: 6px;}
  }

  h2 { margin-bottom: 15px;}

  h3 { 
    font-size: 1em;
    margin-top: 23px;
  }

  h4 { 
    font-size: 1.2em;
    line-height: 1.25;
    margin-top: 5px;
  }

  .orange-text { padding-left:26px;}

  .head-note { margin-bottom: 10px;}

  .foot-note {
    font-size: 0.8em;
    font-style: italic;
    line-height: 1.25;
    font-family: 'pt-serif';
  }

  .push-right { margin-left: 23px;}
}