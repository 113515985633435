/* Colors */
$orange: #D47527;
$light-blue: #02BECE;
$gray: #C1BEBD;
$green: #296857;
$dark-blue: #007197;
$dark-gray: #5F5E5D;

$mild-color: #02BECE;
$moderate-color: #017191;
$severe-color: #2B6757;
$nonsurvival-color: #4E307D;

$button-green: #699744;
$button-border-green: #3D7024;

$slider-green: #7BAC40;
$choice-turquoise: #2A899D


