@import '../../scss/colors';
@import '../../scss/icons';

.es .summary .AudioManager {
  position: relative;
  right: 170px;
}

.es .summary .summary-outcomes .summary-ia-outcomes .right h6 { top: 32px; }

.summary {
  padding-right: 23px;
  padding-top: 8px;

  h3 { letter-spacing: 0 }

  .summary-headnote{
    h2 { 
      font-size: 2em;
      letter-spacing: 0;
      margin-bottom: 16px;
    }
    p { 
      font-size: 1em;
      line-height: 1.2;
    }
  }

  .rule {
    width: 276px;
    margin-top: 30px;
    border: none;
    border-bottom: 1px solid $dark-gray;
    box-sizing: content-box;
  }

  .summary-outcomes {
    margin-top: 17px;
    margin-bottom: 23px;
    width: 100%;

    .spacer {
      height: 18px;
      width: 100%;
     }

    p {
      font-size: 0.825em;
      margin-top: 3px;
      line-height: 1.1;
    }
    .summary-ia-outcomes {
      display: flex;
      flex-direction: row;
      // margin-right: -20px;

      $divider-margin: 5px;
      $end-of-intro: 80px;
      $start-of-legends: 20px;

      .left {
        width: 50%;

        .ia-container { 
          flex-direction: row-reverse;
        }

        h5 {
          text-align: center;
          bottom: $end-of-intro;
          left: 0;
          top: 0;
          width: 150px;
        }

        h6 {
          text-align: left;
          background-position: left;
          padding-right: 0;
          padding-left: 1.2em; 
          top: $start-of-legends;
          margin-left: 13px;

          &.total, &.nonSurvival { margin-left: 0; }
        }
      }

      .right {
        width: 50%;
        border-left: 1px solid $dark-gray;

        .ia-container {
           flex-direction: row;

           .legend {
             width: 130px;
           }
        }

        h5 {
          bottom: $end-of-intro;
          right: 0;
          text-align: center;
          width: 150px;
          top: 0;
        }

        h6 { 
          width: 150px;
          top: 23px;
        }
      }

      .left, .right {
        position: relative;

        h5 {
          font-size: 0.625em; 
          letter-spacing: 0;
          position: absolute;
          text-align: left;
        }

        .ia-container {
          display: flex;

          .iaGrid {
            height: 134px;
            width: 134px;
            flex-shrink: 0;
            margin: 0 $divider-margin;

            .elem{
              opacity: 1;
              background-size: 5.5px 12px;
            }
          }
          
          .legend {
            margin-left: 0;

            h6 { 
              opacity: 1;
              font-size: 0.5em;
              letter-spacing: 0;
              position: relative;
              background-size: 5.5px 12px;
              background-position: left top;
              padding-top: 0; 
              padding-bottom: 0.2em;
              margin-bottom: 0.3em;
            }
          }
        }
      }
    }
  }

  .summary-choices-container {
    h3 { flex: none }

    .summary-choices {
      display: flex;
      flex-direction: row;
      align-items: stretch;
      justify-content: space-between;
      
      .summary-factors {
        width:53%;
        p { margin-bottom: 6px;}
      }
      .summary-quality-of-life {
        width: 47%;
        position: relative;
         left: 8px;
        p { margin-bottom: 8px;}
      }

      .summary-factors, .summary-quality-of-life { 

        h4 {
          font-family: 'pt-serif';
          font-size: 0.65em;
          letter-spacing: 0 ;
          margin-top: 3px;
          margin-bottom: 10px;
          line-height: 1.25;
          padding-right: 38px;
        }

        p {
          font-size: 0.65em;
          line-height: 1.3;
          
          line-height: 1.3;
          span {
            white-space: nowrap;
            color: $choice-turquoise;
            font-family: 'pt-sans';
            font-weight: bold;
            font-size: 1em;
          }
        }
      }

    }
  }

  .show-print-only {
    display: none;
    font-family: 'pt-serif';
    hr.rule { width: 100%;}
  }

  .footnote {
    font-size: 0.65em;
    font-style: italic;
    text-align:center;
    margin-top: auto;
    margin-bottom: 50px;
  }

  .buttons {
    align-self: flex-end;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    z-index: 10;
    position: absolute;
    bottom: 50px;
    right: 61px;

    button {
      margin-left: 27px;
      min-width: 69px;
      height: 30px;
    }
  }
}
