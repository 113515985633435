@import '../../scss/colors';
@import '../../scss/icons';

.en .nonsurvival.severity article { min-height: 480px; }
.es .nonsurvival.severity article { min-height: 535px; }

.nonsurvival {
  .coloredHeading { color:$nonsurvival-color }
  
  .accordion { 
    font-style:italic;
    font-family: 'pt-serif';
    cursor: pointer;
  }

  .show .ia-container {
    .iaGrid .elem.nonSurvival{ background-image: $ribbon-color; }
    .legend h6.nonSurvival{ display: block;}
  }

  &.severity article  h2 {
    margin-top: 126px;
    margin-left: 0;
    letter-spacing: 0.045em;
    margin-bottom: 15px;
  }

  section {
    margin-right: 200px;
    div span {font-size: 0.9em }
    p { margin-top: 10px;}
  }

}